
import MessagesComponent from '@/components/Messages.vue'
import { CampaignService } from '@/services'
import { Campaign } from '@/types'
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'EntitesCampaignsView',
})
export default class EntitesArticleView extends Vue {
  loadingCampaigns = false
  campaigns: Campaign[] = []

  sortBy = 'id'
  sortReverse = false

  async mounted(): Promise<void> {
    this.campaigns = await this.fetchCampaigns()
  }

  async reloadCampaigns(ev: Event | null = null): Promise<void> {
    ev?.preventDefault()
    this.campaigns = await this.fetchCampaigns()
  }

  async fetchCampaigns(): Promise<Campaign[]> {
    this.loadingCampaigns = true
    try {
      return CampaignService.fetchCampaigns(false)
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
    } finally {
      this.loadingCampaigns = false
    }

    return []
  }

  sort(columnName: string): void {
    if (columnName === this.sortBy) {
      this.sortReverse = !this.sortReverse
    } else {
      this.sortBy = columnName
      this.sortReverse = false
    }

    this.campaigns = this.campaigns.sort((a: Campaign, b: Campaign) => {
      const modifier = this.sortReverse ? -1 : 1

      switch (this.sortBy) {
        case 'id':
          return a.id > b.id ? modifier : -modifier
        case 'name':
          return a.name.localeCompare(b.name, 'sv-SE') * modifier
        case 'abbreviation':
          return a.abbreviation.localeCompare(b.abbreviation, 'sv-SE') * modifier
        case 'active':
          return a.active < b.active ? modifier : -modifier
      }

      return 0
    })
  }
}
